<template>
   <div class="container-sm section-default">
    <div class="form-card">
      <div class="header">
        <h1> Faça login na sua<br/>conta <span class="green-text">SFiT</span></h1>
      </div>
      <form id="contato-form" @submit="sendForm" class="row g-3 form-login">
        <div class="col-12">
          <label for="email" class="form-label">Email</label>
          <input type="text" class="form-control" id="email" name="email" v-model="email" placeholder="Informe seu email">
        </div>
        <div class="col-12">
          <label for="senha" class="form-label">Senha</label>
          <input type="password" class="form-control" id="senha" name="senha" v-model="senha" placeholder="Digite sua senha">
        </div>
        <div class="form-login_options">
          <router-link to="/esqueceu-senha" class="rvl-link">Esqueceu a senha?</router-link>
        </div>  
        <div class="d-grid col-12 mx-auto">
          <button type="submit" class="btn form-button">Enviar</button>
        </div>
        <div class="form-login_options">
          <span>Não possui uma conta? 
            <router-link to="/cadastro-profissionais" class="rvl-link">Criar</router-link>
          </span>
        </div>
      </form>
    </div>
   </div>
</template>

<script>
// import { defineComponent } from '@vue/composition-api'

export default {
  
  name:'Fale',
  data() {
    return {
      email:null,
      senha:null
    }
  },
  methods: {
    
    async sendForm(e) {
      e.preventDefault();

      const data = {
        email: this.email,
        senha: this.senha
      }

      const dataJson = JSON.stringify(data)

      console.log(dataJson)

      const req = await fetch('http://31.170.165.72:8080/v1/contact',{
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body:dataJson

      });

      const res = await req.json()

      console.log(res)

    }
  }
}
</script>


<style scoped>

.form-login {
  width: 450px;
  height: auto;
}

.form-card {
    display: grid;
    place-items: center;
    position: relative;
    text-align: start;
    font: normal normal 400 14px/20px Open Sans;
}

.header {
  padding-right: 120px;
  padding-bottom: 20px;
}

.form-card h1 {
    color: #3256a1;
    font: normal normal 500 36px/44px Antenna;
}

</style>